.product-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.product-image {
  width: 25%;
  height: 25%;
  margin-bottom: 20px;
}

.product-detail h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.product-detail p {
  font-size: 16px;
  color: #333;
  margin: 5px 0;
  margin-bottom: 20px;
}

.product-detail button {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.product-detail button:hover {
  background-color: #4C9F50;
}

.product-detail .description {
  font-size: 14px;
  color: #666;
  text-align: justify;
  line-height: 1.5;
}
