.cart {
    padding: 20px; 
    max-width: 800px; 
    margin: 0 auto; 
    background: #f7f7f7; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin-top: 20px;
    min-height: 100vh;
  }
  
  .cart h2 {
    text-align: center; 
    font-size: 34px; 
    font-weight: bold; 
    color: #4CAF50; 
    padding-bottom: 10px; 
    border-bottom: 1px solid #ddd; 
    margin-bottom: 20px; 
  }
  
  .cart h4 {
    text-align: center; 
    font-size: 16px;
    color: black; 
    padding: 30px 0;
  }
  
  .cartItem {
    display: flex;
    align-items: center; 
    padding: 20px; 
    border-bottom: 1px solid #ddd; 
  }
  
  .cartItem img {
    width: 150px; 
    height: 200px;  
    object-fit: cover;  
    border-radius: 8px;  
    margin-right: 20px;  
  }
  
  .description {
    flex: 1;  
  }
  
  .description p {
    margin: 0; 
    color: black;  
    font-size: 16px;  
  }
  
  .countHandler {
    display: flex;  
    align-items: center;  
    margin-top: 10px;  
  }
  
  .countHandler button {
    background-color: #4CAF50;  
    color: white;  
    border: none; 
    border-radius: 5px;  
    padding: 5px 10px;
    cursor: pointer;  
  }
  
  .countHandler input {
    width: 40px;  
    text-align: center; 
    border: 1px solid #ddd;  
    border-radius: 5px;  
    margin: 0 10px;  
  }

  .checkout {
    display: flex;  
    justify-content: space-between;  
    align-items: center;  
    padding: 20px;  
    border-top: 1px solid #ddd; 
  }
  
  .checkout p {
    font-size: 16px;  
    font-weight: bold;  
  }
  
  .checkout button {
    background-color: #4CAF50;  
    color: white;  
    padding: 10px 20px;  
    border: none; 
    border-radius: 5px; 
    font-weight: bold;  
    cursor: pointer; 
  }
  
  .checkout button:hover {
    background-color: #45A049;  
  }

@media (max-width: 768px) {
  .cart {
    padding: 10px;  
    max-width: 100%; 
  }

  .cart h2 {
    font-size: 28px;  
  }

  .cartItem {
    flex-direction: column;  
    align-items: flex-start;  
    padding: 10px;  
    border-bottom: 1px solid #ddd;  
  }

  .cartItem img {
    width: 100%; 
    height: auto;  
    margin-bottom: 10px;  
  }

  .description {
    text-align: left;  
  }

  .countHandler {
    flex-direction: row;  
    justify-content: space-between;  
    width: 100%;
  }

  .countHandler input {
    width: 30px;  
  }

  .checkout {
    flex-direction: column;  
    align-items: center;  
    padding: 10px;  
  }

  .checkout p {
    font-size: 14px; 
  }

  .checkout button {
    padding: 8px 16px;  
    margin-top: 10px;  
  }
}
