/* App.css */

/* Reset styles */
* {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hides horizontal overflow */
}

/* Logo styles */
.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px; 
}

/* Navigation bar styles */
.navigation {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
}

.navigation-link {
  display: inline-block;
  margin-left: 10px; 
  padding: 10px 15px;
  color: black;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigation-link:hover {
  color: #4CAF50; 
}

/* Main content styles */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; 
  color: black;
}

.title{
  margin-top: 50px; 
  font-size: 32px;
}

.card {
  width: 70%;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 120px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.title2{
  color: black;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Columns styles */
.columns-container {
  display: flex;
  justify-content: space-between;
  width: 85%;
}

.column {
  flex: 1;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  margin: 0 10px;
}

.column h5 {
  margin-bottom: 10px;
}

.column p {
  margin-bottom: 20px;
}

.title3 {
  margin-top: 50px;
  display: flex; /* Use flex layout for horizontal alignment */
  justify-content: space-between; /* Space between sections */
  align-items: center; /* Align content vertically */
  padding: 20px; /* Padding for spacing */
  font-size: 30px; /* Font size for the text */
  color: black; /* Text color */
}

.title3-text {
  flex: 1; /* Take the other 50% of the width */
  display: flex; /* Use flex for vertical alignment within the text section */
  flex-direction: column; /* Ensure vertical stacking of header and paragraph */
  padding-left: 20px; /* Padding for spacing */
}

.title3-text h6 {
  font-size: 16px; /* Font size for the text */
  color: #4CAF50;
}

.title3-text p {
  font-size: 18px;
  padding-top: 30px;
}

.side-image {
  flex: 1; /* Take 50% of the width */
  overflow: hidden; /* Prevent overflow issues */
  padding: 20px; /* Add padding for spacing */
}

.side-image img {
  width: 90%; 
  height: 90%; 
  object-fit: cover; 
}


.footer {
  background-color: #B0B0B0;
  color: black;
  padding: 20px;
  margin-top: 30px;
  text-align: center;
  width: 100%;
  font-size: 16px;
}

.contact-info {
  margin-top: 10px;
}

.icon {
  display: flex; /* Flex layout for horizontal alignment */
  gap: 20px; /* Space between the icons */
  justify-content: center; /* Center the icons */
  margin-top: 20px;
}

.copyright{
  margin-top: 20px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .navigation {
    margin-top: 50px;
    display: block; /* Stack links vertically */
    text-align: center; /* Center-align the links */
  }

  .navigation-link {
    display: block; /* Each link on a new line */
    margin: 10px 0; /* Add margin for spacing */
  }

  .logo {
    left: 50%;
    transform: translateX(-50%); /* Center the logo */
  }

  .columns-container {
    display: flex;
    flex-direction: column; /* Stack columns vertically */
    justify-content: center; /* Center-align content */
    align-items: center; /* Center-align content */
  }

  .column {
    width: 100%; /* Full width for mobile */
    padding: 20px; /* Padding for spacing */
    text-align: center; /* Center-align text */
  }

  .column img {
    max-width: 80%; /* Keep image within bounds */
    object-fit: contain; /* Maintain aspect ratio */
  }
}