.signin-container {
  max-width: 60%;
  margin: 100px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 24px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #4CAF50;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.error {
  color: #f00;
  margin-bottom: 15px;
}

.signin-container p {
  font-size: 16px;
}

.login-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.login-button button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  width: 350px;
  font-size: 16px;
}

.login-button button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

.login-button button:active {
  background-color: #388e3c;
  transform: translateY(0);
}

.login-button button:first-child {
  background-color: #4CAF50;
}

.login-button button:nth-child(2) {
  background-color: #DB4437;
}

.login-button button:nth-child(3) {
  background-color: #2196F3;
}
