.checkout-page {
  max-width: 800px;
  margin: 20px auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

#shippingAddress {
  display: none;
}

.checkout-summary {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.summary-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.summary-item img {
  width: 80px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.checkout-summary p {
  margin-top: 5px;
  font-size: 18px;
}

.button {
  background-color: #4CAF50;
  color: white;
  margin-top: 20px;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: #45A049;
}
