.shop-page {
  color: #111;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to bottom left, #4CAF50, #add8e6);
}

.header-content {
  flex: 1;
}

.header-content h1 {
  padding-left: 20px;
  font-size: 46px;
}

.header-content p {
  padding-left: 20px;
  padding-top: 20px;
  font-size: 22px;
}

.header-image {
  flex: 1;
  overflow: hidden;
}

.header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.products-section {
  background-color: white;
  padding: 20px;
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 80px;
  grid-gap: 20px;
}

.product {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.product img {
  max-width: 100%;
  height: 300px;
  border-radius: 8px;
  margin-bottom: auto;
}

.product h3 {
  margin-top: auto;
  font-size: 18px;
  color: #333;
}

.product p {
  font-size: 16px;
  color: #555;
  margin-top: auto;
}

.addtocart {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  margin-top: auto;
}

.addtocart:hover {
  background-color: #4C9F50;
  transform: translateY(-2px);
}

.addtocart:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding-top: 40px;
  }

  .product img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .product h3 {
    font-size: 16px;
    color: #333;
  }

  .product p {
    font-size: 14px;
    color: #555;
  }

  .addtocart {
    padding: 8px 16px;
  }

  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .header-content {
    padding: 10px;
  }

  .header-content h1 {
    font-size: 32px;
  }

  .header-content p {
    font-size: 18px;
  }

  .header-image {
    width: 100%;
  }

  .header-image img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}
